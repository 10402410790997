export interface NewTemplateFile {
  title: string;
  selectedFile: File | null;
  description: string;
}

export const initNewTemplateFile: NewTemplateFile = {
  title: "",
  selectedFile: null,
  description: "",
};

export const allowedExtensions = [
  "dotx",
  "docx",
  "xltx",
  "potx",
  "pptx",
  "xlsx",
  "odt",
  "otg",
];

export type Editor_Type = "WRITER" | "PRESENTATION" | "SPREADSHEET" | "DRAW";
type Support_Type = {
  [key in Editor_Type]: string[];
};

export const fileType: Support_Type = {
  "WRITER": ["dotx", "docx"],
  "PRESENTATION": ["potx", "pptx"],
  "SPREADSHEET": ["xltx", "xlsx"],
  "DRAW": ["odt", "otg"],
};

export interface TemplateResponse {
  id: string;
  title: string;
  tumbnailFileId: string;
  type: string;
}

