import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useMemo } from "react";

dayjs.extend(utc);

export function formatDateToCustomString(inputDate: string): string {
  return dayjs(inputDate)
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 30)
    .set("millisecond", 0)
    .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
}

export const useMarks = (submissionData) => {
  return useMemo(() => {
    if (!submissionData) return [];
    return submissionData.deadlineSubmissions.map((ds) => {
      const index = submissionData.submissionLog.logs.reduce(
        (nearestIndex, current, currentIndex, array) => {
          const currentDiff = Math.abs(new Date(current.timestamp).getTime() - new Date(ds.date).getTime());
          const nearestDiff = Math.abs(new Date(array[nearestIndex].timestamp).getTime() - new Date(ds.date).getTime());
          return currentDiff < nearestDiff ? currentIndex : nearestIndex;
        },
        0 // Start with the first index as the nearest
      );

      return {
        value: index,
        label: ds.title,
      };
    });
  }, [submissionData]);
};
