import React, { useEffect, useMemo, useState } from "react";
import { ResponsiveLine, Serie } from "@nivo/line";
import { useDispatch, useSelector } from "react-redux";
import { setCurrent } from "../../../../store/Rewind/actions";
import { useMarks } from "./services";
import { useTheme } from "@mui/material";

interface Log {
  change: number;
  // Add other properties if present in your data
}

interface Assignment {
  submissionData: {
    submissionLog: {
      logs: Log[];
    };
  };
}

const ChangeLineGraph: React.FC = () => {
  const theme = useTheme();
  const { current } = useSelector((state: any) => state.rewind);
  const assignment: Assignment = useSelector((state: any) => state.assignment);
  const dispatch = useDispatch();

  useEffect(() => {
    const data: Serie[] = [
      {
        id: "line",
        data: assignment.submissionData.submissionLog.logs.map(
          (log: Log, index: number) => ({
            x: index,
            y: log.change,
          })
        ),
      },
    ];

    setData(data);
  }, [current, assignment.submissionData.submissionLog.logs]);

  const [data, setData] = useState<Serie[]>([]);

  const submissionMark = useMarks(assignment.submissionData);

  const AreaLayer = (submissionMark: any[], dt: any) => {
    const rectsAndTriangles = useMemo(() => {
      return submissionMark.map((mark) => {
        const place = mark.value;
        const rectX = dt.xScale(place);
        const triangleWidth = 10; // Width of the triangle
        const triangleHeight = 10; // Height of the triangle
        const rectWidth = 1;
        const offset = 8;

        return (
          <g key={place}>
            {/* Rectangle */}
            <rect
              x={rectX}
              width={rectWidth}
              height={dt.height}
              fill={theme.palette.secondary.light}
            />
            {/* Inverted Triangle */}
            <polygon
              points={`
                ${rectX + rectWidth / 2},${offset} 
                ${rectX + rectWidth / 2 - triangleWidth / 2},${
                -triangleHeight + offset
              } 
                ${rectX + rectWidth / 2 + triangleWidth / 2},${
                -triangleHeight + offset
              }
              `}
              fill={theme.palette.secondary.light}
            />
          </g>
        );
      });
    }, [submissionMark, dt]);

    return (
      <g>
        {rectsAndTriangles}
        <g>
          <rect
            x={dt.xScale(current - 1)}
            width={2}
            height={dt.height}
            fill={theme.palette.error.dark}
          />
        </g>
      </g>
    );
  };

  const handlePointClick = (point: any) => {
    dispatch(setCurrent(point.data.x));
  };

  return (
    <div style={{ height: "6vh" }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 2, bottom: 2, left: 2 }}
        xScale={{ type: "linear", min: "auto", max: "auto" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        layers={[
          "grid",
          "markers",
          "areas",
          (dt: any) => AreaLayer(submissionMark, dt),
          "lines",
          "axes",
          "points",
          "legends",
          "crosshair",
          "mesh"
        ]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          legend: "Version",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={null}
        curve="monotoneX"
        enablePoints={false}
        enableGridX={false}
        enableGridY={false}
        useMesh={true}
        colors={{ scheme: "category10" }}
        pointColor={{ theme: "background" }}
        pointBorderColor={{ from: "serieColor" }}
        onClick={(point) => {
          handlePointClick(point);
        }}
        tooltip={({ point }) => (
          <div
            style={{
              position: "absolute",
              background: "white",
              padding: "10px",
              border: "1px solid #ccc",
            }}
          >
            <strong>Frame#{point.data.xFormatted}</strong>
            <br />
            Word Change: {point.data.yFormatted}
          </div>
        )}
      />
    </div>
  );
};

export default ChangeLineGraph;
