/* eslint-disable import/no-anonymous-default-export */ /* eslint-disable import/no-anonymous-default-export */
import {
  ActionType,
  ADD_FEEDBACK_SUCCESS,
  EVALUATE_SUBMISSION_SUCCESS,
  FINALIZE_SUBMISSION_SUCCESS,
  GET_SUBMISSION_DATA_SUCCESS,
  GET_SUBMISSION_LOGS_SUCCESS,
  GET_TIME_METRICS_SUCCESS,
  GRADE_SUBMISSION_SUCCESS,
  RE_OPEN_SUBMISSION_SUCCESS,
  END_COMMENTING,
  DRAFT_SUBMIT,
  DRAFT_GRADING,
  SET_DRAFT_PERMISSION,
  DRAFT_ALT_GRADING,
} from "./actions";
import { ReadAssignmentState, initialState } from "./model";

export default (
  state = initialState,
  action: ActionType
): ReadAssignmentState => {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBMISSION_DATA_SUCCESS:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          ...payload.submissionData,
        },
      };
    case RE_OPEN_SUBMISSION_SUCCESS:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          ...payload.submissionData,
        },
      };
    case FINALIZE_SUBMISSION_SUCCESS:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          ...payload.submissionData,
        },
      };
    case EVALUATE_SUBMISSION_SUCCESS:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          ...payload.submissionData,
        },
      };
    case GRADE_SUBMISSION_SUCCESS:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          ...payload.submissionData,
        },
      };
    case GET_TIME_METRICS_SUCCESS:
      return {
        ...state,
        timeMetrics: payload.timeMetrics,
      };
    case GET_SUBMISSION_LOGS_SUCCESS:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          submissionLog: payload.submissionLogs,
        },
      };
    case ADD_FEEDBACK_SUCCESS:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          feedbacks: payload.feedbacks,
        },
      };
    case END_COMMENTING:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          ...payload.submissionData,
        },
      };
    case DRAFT_SUBMIT:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          ...payload.submissionData,
        },
      };

    case SET_DRAFT_PERMISSION:
      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          ...payload.submissionData,
        },
      };

    case DRAFT_GRADING:
      var drafts = state.submissionData.deadlineSubmissions;
      drafts = drafts.map((draft) => {
        if (draft.draftSubmissionId === payload.id) {
          return {
            ...draft,
            grade: payload.grade,
          };
        } else {
          return draft;
        }
      });

      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          deadlineSubmissions: drafts,
        },
      };
    case DRAFT_ALT_GRADING:
      var gdrafts = state.submissionData.deadlineSubmissions;
      gdrafts = gdrafts.map((draft) => {
        if (draft.draftSubmissionId === payload.id) {
          return {
            ...draft,
            alternateGrade: payload.alternateGrade,
          };
        } else {
          return draft;
        }
      });

      return {
        ...state,
        submissionData: {
          ...state.submissionData,
          deadlineSubmissions: gdrafts,
        },
      };
    default:
      return state;
  }
};
