import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { initReplicateProps, ReplicateProps } from "./model";

interface ReplicateReviewerProps {
  data: ReplicateProps;
  setData: (d: ReplicateProps) => void;
}

const ReplicateReviewer: React.FC<ReplicateReviewerProps> = ({
  data,
  setData,
}) => {
  const assignments: any[] = useSelector((state: any) =>
    state.classDetails.assignments.filter((c: any) => c?.peerReview?.length > 0)
  );
  return (
    <Dialog onClose={() => setData(initReplicateProps)} open={data.open}>
      <DialogTitle>Copy peer reviewers from:</DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          {assignments.map((assignment) => (
            <ListItem disablePadding key={assignment.id}>
              <ListItemButton
                onClick={() =>
                  setData({ open: false, peers: assignment.peerReview })
                }
              >
                <ListItemText primary={assignment.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default ReplicateReviewer;
