import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import AbcIcon from "@mui/icons-material/Abc";
import { OauthLoginProps } from "./model";


export const oauthLogins:OauthLoginProps[] = [
  { color: "black", title: "GOOGLE", link: "/oauth2/authorization/google", icon: <GoogleIcon />, external: false },
  { color: "black", title: "GITHUB", link: "/oauth2/authorization/github", icon: <GitHubIcon /> ,external: false},
  { color: "black", title: "LIBRETEXTS", link: "https://aw.libretexts.org/login", icon: <AbcIcon /> , external: true},
];
