import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrent } from "../../../../store/Rewind/actions";
import { SubmissionDetails } from "../../../../services/models/assignments.model";
import { Slider } from "@mui/material";
import { useMarks } from "./services";

const RewindSlider: React.FC = () => {
  const dispatch = useDispatch();
  const submissionData: SubmissionDetails = useSelector(
    (state: any) => state.assignment.submissionData
  );

 

  const { current } = useSelector((state: any) => state.rewind);

  const setVersion = (_event: any, value: any) => {
    dispatch(setCurrent(value));
  };
  return (
    <Slider
      aria-label="pretto slider"
      defaultValue={0}
      value={current}
      valueLabelDisplay="auto"
      onChangeCommitted={setVersion}
      step={1}
      marks={useMarks(submissionData)}
      min={1}
      max={submissionData.submissionLog.logs.length - 1}
      sx={{
        height: "1vh",
      }}
    />
  );
};

export default RewindSlider;
