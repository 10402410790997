import React, { ChangeEvent, useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import {
  allowedExtensions,
  fileType,
  initNewTemplateFile,
  NewTemplateFile,
  TemplateResponse,
} from "./model";
import { useDispatch } from "react-redux";
import { AlertTypes } from "../../store/UIActions/Snackbar/model";
import { setSnackbarMessage } from "../../store/UIActions/Snackbar/actions";
import {
  loadTemplates,
  updateAssignmentTemplates,
  uploadFile,
} from "./services";
import TitlebarImageList from "./TemplateList";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TemplateProps } from "../instructor/ClassDetails/model";
import { AssignmentTemplateResponse } from "../instructor/ClassDetails/Assignments/FileTemplates/model";

interface TemplateManagementProps extends TemplateProps {
  handleClose: () => void;
  handleSave: (response: AssignmentTemplateResponse) => void;
  selectTemplate: AssignmentTemplateResponse | undefined;
}

const TemplateManagement: React.FC<TemplateManagementProps> = ({
  open,
  type,
  handleClose,
  handleSave,
  selectTemplate,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<NewTemplateFile>(initNewTemplateFile);
  const [error, setError] = useState<string | null>(null);
  const [selected, setSelected] = React.useState<string>("");
  const [templateList, setTemplateList] = useState<TemplateResponse[]>([]);
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = event.target;

    if (files?.length) {
      // Handle file selection
      const file = files[0];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        setState({ ...state, selectedFile: file });
        setError(null);
      } else {
        dispatch(
          setSnackbarMessage({
            type: AlertTypes.ERROR,
            showSnackBar: true,
            message:
              "Invalid file type. Please upload a valid Office template file.",
          })
        );
        event.target.value = ""; // Reset the file input
      }
    } else {
      // Handle other input changes
      setState({ ...state, [name]: value });
    }
  };

  useEffect(() => {
    const loading = async () => {
      const response = await loadTemplates();
      if (type) {
        setTemplateList(
          response.data.filter((c) => fileType[type].includes(c.type))
        );
        return;
      }
      setTemplateList(response.data);
    };
    if (type) loading();
  }, [type]);
  useEffect(() => {
    if (!!selectTemplate)
      setSelected(
        selectTemplate.templateFileId ? selectTemplate.templateFileId : ""
      );
  }, [selectTemplate]);

  const handleUpload = async (e: any) => {
    e.preventDefault();
    if (!state.selectedFile) {
      setError("File is required.");
      return;
    }
    try {
      const response = await uploadFile(state);
      if (response.status === 200) {
        setTemplateList((prev) => [response.data, ...prev]);
        setSelected(response.data.id);
        setState(initNewTemplateFile);
      }
    } catch (e) {
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: "Failed to upload the file.",
        })
      );
    }
  };

  const handleApply = async () => {
    try {
      if (!!selectTemplate) {
        const response = await updateAssignmentTemplates(
          selectTemplate.id,
          selected
        );
        if (response.status === 200) {
          handleSave(response.data);
        }
      }
    } catch (e) {}
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      maxWidth="md"
      // TransitionComponent={Transition}
    >
      <DialogTitle>
        <Button
          onClick={handleClose}
          startIcon={<ArrowBackIcon />}
          sx={{ color: "ButtonText" }}
        ></Button>
        Template -{selected}
      </DialogTitle>

      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ mx: 2, p: 4 }}>
            <Box
              sx={{
                my: 0,
                // mx: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{ m: 1, bgcolor: "secondary.main", width: 32, height: 32 }}
              >
                <PostAddIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Add Template File
              </Typography>
            </Box>
            <Divider />
            <Grid
              container
              spacing={2}
              component="form"
              onSubmit={handleUpload}
              name="uploadTemplate"
              id="uploadTemplate"
            >
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Button variant="contained" component="label">
                  Select File
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept={
                      !type
                        ? allowedExtensions.map((ext) => `.${ext}`).join(",")
                        : fileType[type].map((ext) => `.${ext}`).join(",")
                    }
                    onChange={handleInputChange}
                    name="selectedFile"
                  />
                </Button>
                {state.selectedFile && (
                  <Typography variant="caption" sx={{ ml: 2 }}>
                    {state.selectedFile.name}
                  </Typography>
                )}
                {error && (
                  <FormHelperText sx={{ color: "error.main", mt: 1 }}>
                    {error}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="title"
                  name="title"
                  label="Template Title"
                  fullWidth
                  variant="standard"
                  margin="normal"
                  onChange={handleInputChange}
                  value={state.title}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  name="description"
                  label="Template description"
                  fullWidth
                  variant="standard"
                  margin="normal"
                  onChange={handleInputChange}
                  value={state.description}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" fullWidth variant="outlined">
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TitlebarImageList
            templates={templateList}
            selected={selected}
            setSelected={setSelected}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Grid container>
          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={selected ? false : true}
              onClick={handleApply}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateManagement;
