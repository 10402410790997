import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "./auth-header";
import { SubmissionStatus, TimeMetrics } from "./models/classes.model";
import { SubmissionDetails } from "./models/assignments.model";
import { SubmissionLogs } from "../store/ReadAssignment/model";
import { LetterGradesType } from "../containers/instructor/CreateCourse/model";
import { Dayjs } from "dayjs";
const HOST = process.env.REACT_APP_API_URL;
const CLASSES = "api/classes";
const TIME_METRICS = "getTimeConsumed";
const SET_GRADE = "setGrade";
const SET_ALT_GRADE = "setAlternateGrade";
const SET_FINAL = "setFinal";
const SET_EVALUATE = "addToEvaluate";
const SET_REOPEN = "setEnableWriting";
const SET_DRAFT_PERMISSION = "setDraftResubmit";
const SET_REVISE = "setToRevise";
const SUBMISSION_LOGS = "readsubmissionlogs";
const END_COMMENT = "endComment";
const SUBMIT = "submit";
const DRAFTGRADE = "draft/grade"
const DRAFTALTGRADE = "draft/altgrade"

export const setToRevise = async (classId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${SET_REVISE}`,
    data: {},
    params: { id: classId },
  };
  try {
    const apiCall: AxiosResponse<SubmissionStatus> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
export const getAssignmentTimeMetrics = async (assignmentId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${TIME_METRICS}`,
    params: { id: assignmentId },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<TimeMetrics> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const setGrade = async (submissionId: string, grade: number) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${SET_GRADE}`,
    data: {},
    params: { id: submissionId, grade },
  };
  try {
    const apiCall: AxiosResponse<any> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
export const setAltGrade = async (submissionId: string, grade: LetterGradesType) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${SET_ALT_GRADE}`,
    data: {},
    params: { id: submissionId, grade },
  };
  try {
    const apiCall: AxiosResponse<any> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const setToFinal = async (submissionId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${SET_FINAL}`,
    data: {},
    params: { id: submissionId },
  };
  try {
    const apiCall: AxiosResponse<SubmissionDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const setToEvaluate = async (submissionId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "PUT",
    url: `${HOST}/${CLASSES}/${SET_EVALUATE}`,
    data: {},
    params: { submissionId },
  };
  try {
    const apiCall: AxiosResponse<any> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const setToReOpen = async (submissionId: string, status:boolean = true) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${SET_REOPEN}`,
    data: {
      status,
      submissionId,
    },
  };
  try {
    const apiCall: AxiosResponse<SubmissionDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const setDraftPermission = async (submissionId: string, draftSubmissionId:string, status:boolean = true, exceptionDeadline:Dayjs|null) =>{
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${SET_DRAFT_PERMISSION}`,
    data: {
      draftSubmissionId,
      status,
      submissionId,
      exceptionDeadline
    },
  };
  try {
    const apiCall: AxiosResponse<SubmissionDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}


export const getSubmissionLogs = async (submissionId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${SUBMISSION_LOGS}`,
    params: {
      submissionId,
    },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<SubmissionLogs> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const endCommenting = async (
  submissionId: string,
  draftSubmissionId: string
) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${END_COMMENT}`,
    params: {},
    data: {
      submissionId,
      draftSubmissionId,
    },
  };
  try {
    const apiCall: AxiosResponse<SubmissionDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const submitDraft = async (submissionId: string, draftId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${SUBMIT}`,
    params: {},
    data: {
      submissionId,
      draftId,
    },
  };
  try {
    const apiCall: AxiosResponse<SubmissionDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }


};

export const draftGrading = async(draftSubmissionId:string,grade:number) =>{

  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${DRAFTGRADE}`,
    params: {},
    data: {
      draftSubmissionId,
      grade,
    },
  };
  try {
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}


export const draftAltGrading = async(draftSubmissionId:string,alternateGrade: LetterGradesType) =>{

  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${DRAFTALTGRADE}`,
    params: {},
    data: {
      draftSubmissionId,
      alternateGrade,
    },
  };
  try {
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}
