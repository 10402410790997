import React, {  useMemo, useState } from "react";
import {
  Card,
  Box,
  IconButton,
  Typography,
  CardContent,
  CardActions,
  Button,
  TextField,
  Stack,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import SendIcon from "@mui/icons-material/Send";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import LaptopOutlinedIcon from "@mui/icons-material/LaptopOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { gradeStatus } from "../constants";
import { apiIds } from "../../../../store/ReadAssignment/constants";
import { isBefore, parseISO } from "date-fns";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CommentPanel from "./CommentPanel";
import SubmissionPanel from "./SubmissionPanel";
import GradeBook from "./GradeBook";
import { isExcepted, showCommenting } from "./utils";
import {
  SUBMIT_TOOLTIP,
  ENDCOMMENT_TOOLTIP,
  EVAL_TOOLTIP,
  GB_TOOLTIP,
  REOPEN_EXCEPTION_TOOLTIP,
} from "./TooltipText";
import ReopenException from "./ExceptionPanel/ReopenException";
import { useSelector } from "react-redux";
import { DeadlineSubmission } from "../../../../store/ReadAssignment/model";
import { gradeType, LetterGradesType } from "../../CreateCourse/model";

import InsightsIcon from "@mui/icons-material/Insights";
import { AnalyticsOpenProps, initAnalyticsOpenState } from "../Analytics/model";
import AssignmentAnalyticsDashboard from "../Analytics/Assignment/Dashboard";
import StudentAnalyticsDashboard from "../Analytics/Student/Dashboard";

interface GradeSubmissionProps {
  assignment: any;
  handleMove: (index: number) => void;
  handleSetToRevise: () => void;
  handleSetGrade: () => void;
  handleSetAltGrade: () => void;
  handleSetToFinal: () => void;
  onGradeChange: (event: any) => void;
  onSetReOpen: () => void;
  loadingState: any;
  grade: string;
  branch: string;
  alternateGrade: LetterGradesType;
  submissionId: string;
  events: any[];
  setEvents: (events: any[]) => void;
  handleEventSelect: (fileId: string, branch: string, type?: string) => void;
}

function GradeSubmission(props: GradeSubmissionProps) {
  const { events, setEvents, handleEventSelect } = props;

  function disableEvaluateButton() {
    return isBefore(
      parseISO(props.assignment?.lastModification),
      parseISO(props.assignment?.scoresHistory[0]?.timestamp) ||
        props.assignment?.scoresHistory[0]?.graded
    );
  }

  const pinnedEventId = useMemo(() => {
    const pinnedEvent = events.find((e) => e.isPinned); // Use `find()` instead of `filter()[0]`
    return pinnedEvent?.eventId || ""; // Fallback to empty string if no pinned event
  }, [events]);

  const gradeMethod: gradeType = useSelector(
    (state: any) => state.assignment.submissionData.gradeMethod
  );
  const gradeOptions: LetterGradesType[] = useSelector(
    (state: any) => state.assignment.submissionData.gradeOptions
  );

  const deadlineSubmissions: DeadlineSubmission[] | undefined = useSelector<
    any,
    DeadlineSubmission[] | undefined
  >((state) => state.assignment.submissionData?.deadlineSubmissions);

  const submission_status: string | undefined = useSelector<
    any,
    string | undefined
  >((state) => state.assignment.submissionData?.status);

  const additionalFiles: any[] | undefined = useSelector<
    any,
    any[] | undefined
  >((state) => state.assignment.submissionData?.additionalFiles);

  const [commentOpen, setCommentOpen] = useState<boolean>(false);
  const handleCloseComment = () => {
    setCommentOpen(false);
  };
  const handleOpenComment = () => {
    setCommentOpen(true);
  };

  const [submissionOpen, setSubmissionOpen] = useState<boolean>(false);
  const handleCloseSubmission = () => {
    setSubmissionOpen(false);
  };
  const handleOpenSubmission = () => {
    setSubmissionOpen(true);
  };

  const [gradeBookOpen, setGradeBookOpen] = useState<boolean>(false);
  const handleCloseGradeBook = () => {
    setGradeBookOpen(false);
  };
  const handleOpenGradeBook = () => {
    setGradeBookOpen(true);
  };

  const [expPanelOpen, setExpPanelOpen] = useState<boolean>(false);
  const handleCloseExption = () => {
    setExpPanelOpen(false);
  };

  const handleOpenExption = () => {
    setExpPanelOpen(true);
  };

  const [analyticState, setAnalyticState] = useState<AnalyticsOpenProps>(
    initAnalyticsOpenState
  );

  const setIsPinned = (eventId: string) => {
    var updatedData = events.map((item) =>
      "isPinned" in item ? { ...item, isPinned: false } : item
    );
    updatedData = updatedData.map((item) =>
      "isPinned" in item && item.eventId === eventId
        ? { ...item, isPinned: true }
        : item
    );
    setEvents(updatedData);
  };

  const setFileId = (eventId: string) => {
    const draftId = eventId.includes("-") ? eventId.split("-")[1] : eventId;
    const submission = deadlineSubmissions?.find(
      (c) => c.draftSubmissionId === draftId
    );

    if (submission !== undefined && eventId.startsWith("PR")) {
      handleEventSelect(
        eventId.split("-")[2],
        `${submission.title} Peer-Review`
      );
      setIsPinned(eventId);
      return;
    } else if (submission !== undefined && eventId.startsWith("CM")) {
      handleEventSelect(
        submission.commentedFiles[0].fileId,
        `${submission.title} - Comment`
      );
      setIsPinned(eventId);
      return;
    } else if (submission !== undefined) {
      if (submission.fileId === null) return;
      handleEventSelect(submission.fileId, submission.title);
      setIsPinned(eventId);
      return;
    } else if (submission === undefined && eventId.startsWith("AF")) {
      const eid = eventId.split("-")[2];
      const file = additionalFiles?.find((file) => file.fileId === eid);
      const title = file?.title || file?.type;
      handleEventSelect(eid, `Additional File - ${title}`, "additional");
      setIsPinned(eventId);
    } else if (draftId === props.submissionId) {
      setIsPinned(eventId);
      handleEventSelect(draftId, "Ongoing");
    }
  };

  return (
    <Card>
      <Box sx={{ display: "flex", alignItems: "center", px: 1, pb: 1 }}>
        <IconButton
          aria-label="previous"
          sx={{ mr: "auto" }}
          onClick={async () => await props.handleMove(-1)}
          disabled={props.assignment.currentStudentIndex === 0}
        >
          <ChevronLeftOutlinedIcon />
        </IconButton>

        <IconButton aria-label="Indicator">
          <Typography variant="h2">
            {props.assignment.currentStudentIndex + 1} /
            {props.assignment.numberOfStudents}
          </Typography>
        </IconButton>

        <IconButton
          aria-label="next"
          sx={{ ml: "auto" }}
          onClick={async () => await props.handleMove(1)}
          disabled={
            props.assignment.numberOfStudents ===
            props.assignment.currentStudentIndex + 1
          }
        >
          <ChevronRightOutlinedIcon />
        </IconButton>
      </Box>

      <CardContent sx={{ pb: 0 }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.assignment.className}
        </Typography>
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          variant="subtitle2"
        >
          {props.assignment.assignmentTitle}
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() =>
              setAnalyticState({ ...analyticState, assignment: true })
            }
          >
            <InsightsIcon fontSize="inherit" />
          </IconButton>
        </Typography>

        <Typography variant="h6" component="div">
          {props.assignment.studentName}

          <IconButton size="small">
            <InsightsIcon
              fontSize="inherit"
              onClick={() =>
                setAnalyticState({ ...analyticState, student: true })
              }
            />
          </IconButton>
        </Typography>
        <Typography variant="subtitle2" component="div">
          <b>Status:</b> {props.assignment.status}
        </Typography>
        {/* <Typography variant="subtitle2" component="div">
          <b>Version:</b> {props.branch}
        </Typography> */}
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="grouped-native-select">Version</InputLabel>
          <Select
            native
            defaultValue=""
            id="grouped-native-select"
            label="Grouping"
            variant="standard"
            value={pinnedEventId}
            onChange={(e) => setFileId(e.target.value as string)}
          >
            <option aria-label="None" value={`OG-${props.submissionId}`}>
              Ongoing
            </option>
            {!!deadlineSubmissions && deadlineSubmissions.length>0 && (
              <optgroup label="Drafts">
                {deadlineSubmissions.map((d) => (
                  <option value={`SB-${d.draftSubmissionId}`}>{d.title}</option>
                ))}
              </optgroup>
            )}

            {!!deadlineSubmissions &&
              deadlineSubmissions.some((d) => d.commentedFiles.length > 0) && (
                <optgroup label="Comments">
                  {deadlineSubmissions
                    .filter((d) => d.commentedFiles.length > 0)
                    .map((drft) =>
                      drft.commentedFiles.map((cmnt) => (
                        <option
                          key={cmnt.fileId}
                          value={`CM-${drft.draftSubmissionId}-${cmnt.fileId}`}
                        >
                          {drft.title}
                        </option>
                      ))
                    )}
                </optgroup>
              )}
            {!!deadlineSubmissions &&
              deadlineSubmissions.some((d) => d.peerReview.length > 0) && (
                <optgroup label="Peer reviews">
                  {deadlineSubmissions
                    .filter((d) => d.peerReview.length > 0)
                    .map((drft) =>
                      drft.peerReview.map((pr) => (
                        <option
                          key={pr.fileId}
                          value={`CM-${drft.draftSubmissionId}-${pr.fileId}`}
                        >
                          {drft.title}
                        </option>
                      ))
                    )}
                </optgroup>
              )}

            {!!additionalFiles && additionalFiles.length>0 && (
              <optgroup label="Addtional">
                {additionalFiles.map((d) => (
                  <option value={`AF-${d.type.substring(0, 2)}-${d.fileId}`}>
                    {!!d.title ? d.title : d.type}
                  </option>
                ))}
              </optgroup>
            )}
          </Select>
        </FormControl>
        {gradeStatus.Grade.includes(props.assignment.status) && (
          <>
            {gradeMethod === "Numeric" && (
              <>
                <br />
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextField
                    type="number"
                    fullWidth
                    required
                    id="grade"
                    label="grade"
                    name="grade"
                    disabled={props.loadingState.loadingIds.includes(
                      apiIds.gradeSubmission
                    )}
                    placeholder="--"
                    value={parseInt(props.grade) !== -1 && props.grade}
                    onChange={props.onGradeChange}
                    onBlur={() => props.handleSetGrade()}
                    variant="standard"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                  />
                </Box>
              </>
            )}
            {gradeMethod === "Letter" && (
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                fullWidth
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Grade
                </InputLabel>
                <Select
                  value={props.alternateGrade}
                  onChange={props.onGradeChange}
                  onBlur={() => props.handleSetAltGrade()}
                  label="Grade"
                  name="alternateGrade"
                >
                  <MenuItem value={""}>
                    <em>None</em>
                  </MenuItem>
                  {gradeOptions.map((c) => (
                    <MenuItem value={c}>{c}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
      </CardContent>
      <CardActions>
        <Stack
          spacing={{ xs: 1, sm: 1 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {props.assignment.grade === -1 && (
            <Tooltip title={SUBMIT_TOOLTIP} placement="right">
              <Button
                disabled={props.loadingState.loadingIds.includes(
                  apiIds.finalizeSubmission
                )}
                variant="outlined"
                endIcon={
                  <SendIcon
                    sx={{
                      color: "black",
                    }}
                  />
                }
                fullWidth
                onClick={handleOpenSubmission}
              >
                Submit
              </Button>
            </Tooltip>
          )}

          {showCommenting(props.assignment.deadlineSubmissions) && (
            <Tooltip title={ENDCOMMENT_TOOLTIP} placement="right">
              <Button
                disabled={props.loadingState.loadingIds.includes(
                  apiIds.finalizeSubmission
                )}
                variant="outlined"
                endIcon={
                  <RateReviewIcon
                    sx={{
                      color: "black",
                    }}
                  />
                }
                fullWidth
                onClick={handleOpenComment}
              >
                End Commenting
              </Button>
            </Tooltip>
          )}
          {props.assignment.deadlineSubmissions.length > 0 && (
            <Tooltip title={GB_TOOLTIP} placement="right">
              <Button
                fullWidth
                variant="outlined"
                endIcon={
                  <StickyNote2Icon
                    sx={{
                      color: "black",
                    }}
                  />
                }
                onClick={handleOpenGradeBook}
              >
                Grade Book
              </Button>
            </Tooltip>
          )}

          <Tooltip title={EVAL_TOOLTIP} placement="right">
            <Button
              fullWidth
              disabled={
                props.loadingState.loadingIds.includes(
                  apiIds.evaluateSubmission
                ) || disableEvaluateButton()
              }
              variant="contained"
              endIcon={<LaptopOutlinedIcon />}
              onClick={() => {
                props.handleSetToRevise();
              }}
            >
              Evaluate
            </Button>
          </Tooltip>
          <Tooltip title={REOPEN_EXCEPTION_TOOLTIP} placement="right">
            <Button
              fullWidth
              variant="contained"
              endIcon={
                <LockResetOutlinedIcon
                  color={
                    isExcepted(deadlineSubmissions, submission_status)
                      ? "warning"
                      : "inherit"
                  }
                />
              }
              onClick={handleOpenExption}
            >
              Reopen/exception
            </Button>
          </Tooltip>
        </Stack>
      </CardActions>
      <CommentPanel open={commentOpen} handleClose={handleCloseComment} />
      <SubmissionPanel
        open={submissionOpen}
        handleClose={handleCloseSubmission}
      />
      <GradeBook handleClose={handleCloseGradeBook} open={gradeBookOpen} />
      <ReopenException open={expPanelOpen} handleClose={handleCloseExption} />
      <AssignmentAnalyticsDashboard
        open={analyticState.assignment}
        handleClose={() =>
          setAnalyticState({ ...analyticState, assignment: false })
        }
      />
      <StudentAnalyticsDashboard
        open={analyticState.student}
        handleClose={() =>
          setAnalyticState({ ...analyticState, student: false })
        }
      />
    </Card>
  );
}
export default GradeSubmission;
