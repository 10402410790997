import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { Assignment, Student } from "../../../../store/ClassDetails/model";
import AssignmentRow from "./AssignmentRow";
import Analytics from "./Analytics/Dashboard";

interface AssignmentsProps {
  assignments: Assignment[];
  classTimeZone: string;
  students: Student[];
  showDesc: (id: string) => void;
  deleteAssignment: (assignment: Assignment) => void;
  editAssignment: (assignment: Assignment) => void;
  openPeerReview: (assignmentId: string) => void;
}
export default function Assignments(props: AssignmentsProps) {
  const getMappedAssignmentStudents = () => {
    const list: any[] = props.assignments?.map((assignment) => {
      let assignStudents: any = {
        studentSubmissions: [],
      };
      props.students.map((student) => {
        const submissionsFound = student.submissions.filter(
          (submission) => submission.assignmentId === assignment.id
        );
        if (submissionsFound.length >= 1) {
          assignStudents.studentSubmissions.push({
            ...student,
            submissionDetails: submissionsFound[0],
          });
        }
        return assignStudents;
      });
      return {
        ...assignment,
        ...assignStudents,
      };
    });
    return list;
  };

  const [analyticId, setAnalyticId] = useState<string>("");

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Title</TableCell>
              <TableCell align="left">Start Date</TableCell>
              <TableCell align="left">Deadline</TableCell>
              <TableCell align="left">Autosubmit</TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMappedAssignmentStudents().map(
              (assignment: Assignment, index: number) => (
                <AssignmentRow
                  key={index}
                  classTimeZone={props.classTimeZone}
                  assignment={assignment}
                  showDesc={props.showDesc}
                  deleteAssignment={props.deleteAssignment}
                  editAssignment={props.editAssignment}
                  openPeerReview={props.openPeerReview}
                  setAnalyticId={setAnalyticId}

                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Analytics assignmentId={analyticId} handleClose={()=>setAnalyticId("")}/>
    </>
  );
}
