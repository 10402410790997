import React from "react";
import ReactApexChart from "react-apexcharts";
import {
  getAdditionalFileHeatMapLogs,
  getHeatMapLogs,
} from "../../services/classes.service";
import { connect } from "react-redux";
import { Tab, Tabs } from "@mui/material";



const COLORS = {
  MAIN: [
    { from: 0, to: 40, name: "low", color: "#B9B9B9" },
    { from: 40, to: 100, name: "medium", color: "#128FD9" },
    { from: 100, to: 400, name: "high", color: "#3CD4A0" },
    { from: 400, to: 6000, name: "extreme", color: "#FF0000" },
  ],
  PRESENTATION: [{ from: 0, to: 3, name: "low", color: "#F4CCCC" }, { from: 3, to: Number.MAX_VALUE, name: "medium", color: "#E06666" }],
  SPREADSHEET: [{ from: 0, to: 3, name: "low", color: "#D9EAD3" }, { from: 3, to: Number.MAX_VALUE, name: "medium", color: "#6AA84F" }],
  DOCUMENT: [{ from: 0, to: 3, name: "low", color: "#C9DAF8" }, { from: 3, to: Number.MAX_VALUE, name: "medium", color: "#3C78D8" }],
  DRAW: [{ from: 0, to: 3, name: "low", color: "#F9CB9C" }, { from: 3, to: Number.MAX_VALUE, name: "medium", color: "#E69138" }],
};
class HeatmapGraph extends React.Component<any, any> {
  // TODO add interface
  constructor(props: any) {
    super(props);

    this.state = {
      currentTabId: this.props.subid,
      series: {} as {},
      options: {
        chart: {
          height: 600,
          type: "heatmap",
        },
        dataLabels: {
          enabled: false,
        },
        // colors: COLORS.DRAW,

        title: {
          text: "HeatMap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            distributed: true,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: COLORS.MAIN,
            },
          },
        },
      },
    };
  }

  async componentDidMount() {
    const heatMapLogs = await getHeatMapLogs(this.props.subid);
    this.setState((prevState) => ({
      series: {
        ...prevState.series, // Keep existing series data
        [this.props.subid]: JSON.parse(heatMapLogs.data), // Add new data
      },
    }));
  }

  getColor = (newValue: string): any => {
    if (newValue === this.props.subid) {
      return COLORS.MAIN;
    }
  
    const file = this.props.additionalFiles.find((file: any) => file.fileId === newValue);
    
    if (!file || !file.type) {
      console.warn("File type not found for:", newValue);
      return COLORS.MAIN; // Fallback to default
    }
  
    switch (file.type) {
      case "PRESENTATION":
        return COLORS.PRESENTATION;
      case "SPREADSHEET":
        return COLORS.SPREADSHEET;
      case "DOCUMENT":
        return COLORS.DOCUMENT;
      case "DRAW":
        return COLORS.DRAW;
      default:
        return COLORS.MAIN;
    }
  };
  
  handleChangeTab = async (event: React.ChangeEvent<{}>, newValue: string) => {
    const newRanges = this.getColor(newValue); // Always get the right color
    this.setState((prevState) => ({
      currentTabId: newValue,
      options: {
        ...prevState.options,
        plotOptions: {
          ...prevState.options.plotOptions,
          heatmap: {
            ...prevState.options.plotOptions.heatmap,
            colorScale: {
              ranges: newRanges,
            },
          },
        },
      },
    }));
  
    if (!this.state.series[newValue]) {
      const response = await getAdditionalFileHeatMapLogs(newValue, this.props.classTimezone);
      if (response.status === 200) {
        this.setState((prevState) => ({
          ...prevState,
          series: {
            ...prevState.series,
            [newValue]: prevState.series[newValue] ?? response.data,
          },
        }));
      }
    }
  };
  



  render() {
    return (
      <>
        <Tabs
          sx={{ borderRight: 1, borderColor: "divider" }}
          variant="scrollable"
          scrollButtons="auto"
          onChange={this.handleChangeTab}
          value={this.state.currentTabId}
        >
          {this.props.subid && <Tab label="Main" value={this.props.subid} />}
          {this.props.additionalFiles.map((file: any) => (
            <Tab
              label={file.title ? file.title : file.type}
              value={file.fileId}
            />
          ))}
        </Tabs>
        {this.state.series[this.state.currentTabId] && (
          <ReactApexChart
            options={this.state.options}
            series={this.state.series[this.state.currentTabId]}
            type="heatmap"
            height={600}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { additionalFiles, classTimezone } = state.assignment.submissionData;
  return {
    additionalFiles,
    classTimezone,
  };
}

export default connect(mapStateToProps)(HeatmapGraph);
