export type AssignmentTemplateType = "MAIN" | "ADDITIONAL";
export type DocumentContentType =
  | "IMAGE"
  | "DRAW"
  | "DOCUMENT"
  | "SPREADSHEET"
  | "PRESENTATION";

export interface AssignmentTemplateResponse {
  id: string;
  title: string | null;
  templateTitle: string | null;
  templateFileId: string | null;
  type: AssignmentTemplateType;
  contentType: DocumentContentType;
}


export interface NewAssignmentTemplate {
  contentType:DocumentContentType;
  assignmentTemplateType:AssignmentTemplateType;
  assignmentId:string;
  title:string;
}
export const initNewAssignmentTemplate:NewAssignmentTemplate={
  contentType:"DOCUMENT",
  assignmentTemplateType:"ADDITIONAL",
  assignmentId:"",
  title:""
}

export const documentContentTypes: DocumentContentType[] = [
  "DRAW",
  "DOCUMENT",
  "SPREADSHEET",
  "PRESENTATION",
];


export const contentToEditor = {
  "DRAW":"DRAW",
  "DOCUMENT":"WRITER",
  "SPREADSHEET":"SPREADSHEET",
  "PRESENTATION":"PRESENTATION",
}