import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { TemplateResponse } from "./model";
import { useTheme } from "@mui/material";

interface TitlebarImageListProps {
  templates: TemplateResponse[];
  selected: string;
  setSelected: (id: string) => void;
}

const TitlebarImageList: React.FC<TitlebarImageListProps> = ({
  templates,
  selected,
  setSelected,
}) => {
  const theme = useTheme();

  const host = `${process.env.REACT_APP_API_URL}/api/auxfiles/image/`;

  const isSelected = (id: string): boolean => {
    return id === selected;
  };
  return (
    <ImageList
      sx={{
        width: "100%",
        height: 250,
        my: { xs: 3, md: 6 },
        pt: "0!important",
        p: { xs: 2, md: 3 },
      }}
      variant="quilted"
      cols={3}
      rowHeight={121}
    >
      {templates.map((item) => (
        <ImageListItem
          key={item.id}
          onClick={() => setSelected(item.id)}
          sx={{
            ...(isSelected(item.id)
              ? { border: 4, borderColor: theme.palette.primary.light }
              : {}),
            cursor: "pointer",
          }}
        >
          <img
            srcSet={`${host}${item.tumbnailFileId}`}
            src={`${host}${item.tumbnailFileId}`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.type}
            actionIcon={
              <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                aria-label={`info about ${item.title}`}
              >
                <InfoIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default TitlebarImageList;
