import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "../../services/auth-header";
import { NewTemplateFile, TemplateResponse } from "./model";
import { AssignmentTemplateResponse } from "../instructor/ClassDetails/Assignments/FileTemplates/model";

const APIS = {
  HOST: process.env.REACT_APP_API_URL || "",
  AUXFILE: "api/auxfiles",
  TEMPLATE: "template",
  TEMPLATES: "templates",
  ASSIGNMENT: "assignment",
};

export const uploadFile = async (request: NewTemplateFile) => {
  if (!request.selectedFile) {
    throw new Error("Select  file");
  }
  // Create a FormData object to handle file upload
  const formData = new FormData();
  formData.append("title", request.title);
  formData.append("description", request.description);
  formData.append("selectedFile", request.selectedFile); // Ensure `selectedFile` is a File object

  const axiosConfig: AxiosRequestConfig = {
    headers: {
      ...authHeader(), // Include auth headers
      "Content-Type": "multipart/form-data",
    },
    method: "POST",
    url: `${APIS.HOST}/${APIS.AUXFILE}/${APIS.TEMPLATE}`,
    data: formData,
  };

  try {
    const apiCall: AxiosResponse<TemplateResponse> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const loadTemplates = async () => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "GET",
    url: `${APIS.HOST}/${APIS.AUXFILE}/${APIS.TEMPLATES}`,
  };

  try {
    const apiCall: AxiosResponse<TemplateResponse[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const updateAssignmentTemplates = async (
  assignmentTemplateId: string,
  templateId: string
) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${APIS.HOST}/${APIS.AUXFILE}/${APIS.ASSIGNMENT}`,
    params: { assignmentTemplateId, templateId },
  };
  try {
    const apiCall: AxiosResponse<AssignmentTemplateResponse> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
