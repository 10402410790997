import {
  Chip,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  RadioGroup,
  Stack,
  Typography,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CasinoIcon from "@mui/icons-material/Casino";
import PeopleIcon from '@mui/icons-material/People';
import HowToRegIcon from "@mui/icons-material/HowToReg";

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import {
  PeerReviewBin,
  SimpleStudent,
  Student,
} from "../../../../store/ClassDetails/model";
import { randomizeReviwer } from "./services";
import {
  RandomizeRequest,
  ReplicateProps,
  TabType,
  initRandomizeRequest,
  initReplicateProps,
} from "./model";
import { useDispatch } from "react-redux";
import { updateAssignmentPeerReviewAction } from "../../../../store/ClassDetails/actions";
import { setSnackbarMessage } from "../../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../../store/UIActions/Snackbar/model";
import ReplicateReviewer from "./ReplicaReviewer";

interface PeerReviewDialogProps {
  open: boolean;
  assignmentId: string;
  setTab: (tab: TabType) => void;
  setUnsaved: (state: string, status: boolean) => void;
}

const useStyles = {
  binContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 2,
    margin: 2,
    minHeight: "100px",
    backgroundColor: "#f0f0f0",
  },
};

const ReviewerTab: React.FC<PeerReviewDialogProps> = ({
  open,
  assignmentId,
  setTab,
  setUnsaved,
}) => {
  const dispatch = useDispatch();
  const studentLists: Student[] = useSelector(
    (state: any) => state.classDetails.students
  );

  const reviews: PeerReviewBin[] = useSelector(
    (state: any) =>
      state.classDetails.assignments.find((c: any) => c.id === assignmentId)
        ?.peerReview
  );
  useEffect(() => {
    if (reviews !== undefined) {
      if (reviews.length > 0) setBins(reviews);
    }
  }, [reviews, assignmentId]);

  const [replicate, setReplicate] =
    useState<ReplicateProps>(initReplicateProps);

  useEffect(() => {
    const { peers } = replicate;
    if (peers !== undefined) {
      if (peers.length > 0) setBins(peers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replicate.peers]);

  const [randomizeReq, setRandomReq] =
    useState<RandomizeRequest>(initRandomizeRequest);

  const handleChange = (e: any) => {
    setRandomReq({ ...randomizeReq, [e.target.name]: e.target.value });
  };

  const [students, setStudents] = useState<SimpleStudent[]>([]);
  useEffect(() => {
    if (open) {
      setStudents(
        studentLists.map(({ id, firstName, lastName }) => ({
          id,
          name: `${lastName}, ${firstName}`,
        }))
      );
    } else {
      setStudents([]);
    }
  }, [studentLists, open]);

  const [bins, setBins] = useState<PeerReviewBin[]>([]);

  const excludeStudent = (studentId: string) => {
    setStudents(students.filter((c) => c.id !== studentId));
  };

  const setEmptyBean = async () => {
    setBins(
      students.map(({ id, name }) => ({
        id: `reviewee-${id}`,
        name,
        reviewers: [],
      }))
    );
    setUnsaved("reviewer", true);
  };
  useEffect(() => {
    const emptyBin = async () => {
      setRandomReq({ ...randomizeReq, size: students.length });
      if (!reviews) return;
      if (students && reviews.length === 0) {
        await setEmptyBean();
      }
    };
    emptyBin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reviewerId = result.draggableId;
    const reviewee = result.destination.droppableId;

    // to not review himself/herself/themself/zemself
    if (reviewee.includes(reviewerId)) return;

    const updatedReviewee = bins.map((item) => {
      if (item.id === reviewee) {
        const reviewer = students.find((c) => c.id === reviewerId);
        const isDuplicate: boolean = item.reviewers.some(
          (c) => c.id === reviewerId
        );
        if (reviewer && !isDuplicate) {
          return { ...item, reviewers: [...item.reviewers, reviewer] };
        }
      }
      if (
        randomizeReq.mutalReview === "Mutal" &&
        item.id.includes(reviewerId)
      ) {
        const returningReviewer = students.find((c) => reviewee.includes(c.id));
        if (
          returningReviewer &&
          !item.reviewers.some((c) => c.id === returningReviewer.id)
        )
          return { ...item, reviewers: [...item.reviewers, returningReviewer] };
      }
      return item;
    });
    setBins(updatedReviewee);
    setUnsaved("reviewer", true);
  };

  const getAssignedCount = (studentId: string) => {
    // Initialize count
    let count = 0;

    // Iterate through bins to count how many times the student is assigned
    bins.forEach((bin) => {
      bin.reviewers.forEach((reviewer) => {
        if (reviewer.id === studentId) {
          count++;
        }
      });
    });

    return count;
  };

  const removeFromReviewee = (revieweeId: string, reviewerId: string) => {
    const updatedReviewee = bins.map((item) => {
      if (item.id === revieweeId) {
        return {
          ...item,
          reviewers: [...item.reviewers.filter((c) => c.id !== reviewerId)],
        };
      }
      if (
        randomizeReq.mutalReview === "Mutal" &&
        item.id.includes(reviewerId)
      ) {
        return {
          ...item,
          reviewers: [
            ...item.reviewers.filter((c) => !revieweeId.includes(c.id)),
          ],
        };
      }
      return item;
    });
    setBins(updatedReviewee);
    setUnsaved("reviewer", true);
  };

  const handleRandomize = async () => {
    try {
      const response = await randomizeReviwer(
        randomizeReq.size,
        randomizeReq.mutalReview,
        randomizeReq.reviewer_size
      );
      if (response.status === 200) {
        await setEmptyBean();
        const result = response.data;
        const randomBin = bins.map((b, index) => {
          const selected: number[] = result[index];
          let selectedReviewer: SimpleStudent[] = [];
          selected.forEach((c, i) => {
            if (c === 1) {
              const tempStd: SimpleStudent = students[i];
              selectedReviewer.push(tempStd);
            }
          });
          b.reviewers = selectedReviewer;
          return b;
        });
        setBins(randomBin);
        setUnsaved("reviewer", true);
      }
    } catch {}
  };

  const handlePopulate = async () => {
    const rq = bins.map((e) => {
      return {
        id: e.id.split("-")[1],
        reviewers: e.reviewers.map((c) => c.id),
      };
    });
    try {
      // @ts-ignore
      // const setPeerReview:any[] = await setReviewers(assignmentId, peerReview);
      await dispatch(updateAssignmentPeerReviewAction(assignmentId, rq));

      dispatch(
        setSnackbarMessage({
          type: AlertTypes.SUCCESS,
          showSnackBar: true,
          message: `Peer reviewer(s) are assigned successfully`,
        })
      );
      setUnsaved("reviewer", false);
      setTab("Deadlines");
    } catch {}
  };

  return (
    <DialogContent>
      <Grid container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container>
            <Grid item xs={3}>
              <Droppable droppableId="reviewers">
                {(provided: any) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Paper sx={useStyles.binContainer} variant="outlined">
                      <Typography variant="h6">Reviewers</Typography>
                      <List
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 0,
                          width: "100%",
                          maxWidth: 360,
                        }}
                        dense
                      >
                        {students.map((student, index) => {
                          const count = getAssignedCount(student.id);
                          return (
                            <Draggable
                              key={student.id}
                              draggableId={student.id.toString()}
                              index={index}
                            >
                              {(provided: any) => (
                                <ListItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  secondaryAction={
                                    <IconButton
                                      aria-label="exclude"
                                      onClick={() => excludeStudent(student.id)}
                                    >
                                      <CloseIcon
                                        sx={{
                                          color: "inherit",
                                          "&:hover": {
                                            color: "red",
                                          },
                                        }}
                                      />
                                    </IconButton>
                                  }
                                >
                                  <ListItemText
                                    primary={
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span>{student.name}</span>
                                        {count > 0 && (
                                          <Chip
                                            label={count}
                                            size="small"
                                            sx={{ marginLeft: 1 }}
                                          />
                                        )}
                                      </div>
                                    }
                                  />
                                </ListItem>
                              )}
                            </Draggable>
                          );
                        })}
                      </List>
                      {provided.placeholder}
                    </Paper>
                  </div>
                )}
              </Droppable>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={2} flexDirection="row">
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="h6" sx={{ pt: "5px" }}>
                      Reviewees
                    </Typography>{" "}
                    <IconButton onClick={setEmptyBean} size="small">
                      <RestartAltIcon />
                    </IconButton>
                    <Typography variant="subtitle2" sx={{ p: "10px" }}>
                      Mutal Review
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="mutalReview"
                        value={randomizeReq.mutalReview}
                        onChange={handleChange}
                      >
                        {/* "Mutal" | "Optional " | "Unidirectional" */}
                        <FormControlLabel
                          value="Mutal"
                          control={<Radio />}
                          label="Mutal"
                        />
                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional Mutual"
                        />
                        <FormControlLabel
                          value="Unidirectional"
                          control={<Radio />}
                          label="Unidirectional"
                        />
                      </RadioGroup>
                    </FormControl>
                    <InputLabel id="reviewer_size_label">
                      Reviewer(s){" "}
                    </InputLabel>
                    <FormControl sx={{ minWidth: 100 }}>
                      <Select
                        labelId="reviewer_size_label"
                        id="reviewer_size"
                        name="reviewer_size"
                        value={randomizeReq.reviewer_size}
                        onChange={handleChange}
                        variant="standard"
                      >
                        <MenuItem value={1}>One</MenuItem>
                        <MenuItem value={2}>Two</MenuItem>
                        <MenuItem value={3}>Three</MenuItem>
                        <MenuItem value={4}>Four</MenuItem>
                      </Select>
                    </FormControl>
                    <IconButton size="small" onClick={handleRandomize}>
                      <CasinoIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => setReplicate({ ...replicate, open: true })}
                    >
                      <PeopleIcon />
                    </IconButton>
                  </Stack>
                  <Divider />
                </Grid>
                {bins.map((review) => (
                  <Droppable
                    key={`bin-${review.id}`}
                    droppableId={`${review.id}`}
                  >
                    {(provided: any) => (
                      <Grid
                        item
                        xs={3}

                        //   sx={{m:1 }}
                      >
                        <Paper
                          sx={{ ...useStyles.binContainer, m: 0 }}
                          variant="outlined"
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <Typography>{review.name}</Typography>
                            <Divider />
                            <List>
                              {review.reviewers.map((reviewer, index) => (
                                <ListItem
                                  key={reviewer.id}
                                  secondaryAction={
                                    <IconButton
                                      aria-label="exclude"
                                      onClick={() =>
                                        removeFromReviewee(
                                          review.id,
                                          reviewer.id
                                        )
                                      }
                                    >
                                      <CloseIcon
                                        sx={{
                                          color: "inherit",
                                          "&:hover": {
                                            color: "red",
                                          },
                                        }}
                                      />
                                    </IconButton>
                                  }
                                >
                                  <ListItemText primary={reviewer.name} />
                                </ListItem>
                              ))}
                            </List>
                            {provided.placeholder}
                          </div>
                        </Paper>
                      </Grid>
                    )}
                  </Droppable>
                ))}
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    endIcon={<HowToRegIcon />}
                    onClick={handlePopulate}
                  >
                    Apply & Set deadline
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DragDropContext>
      </Grid>
      <ReplicateReviewer data={replicate} setData={setReplicate} />
    </DialogContent>
  );
};

export default ReviewerTab;
