import React, { useState, useCallback, useEffect } from "react";
import { DeadlineSubmission } from "../../../../../store/ReadAssignment/model";
import {
  Button,
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setDraftPermissionAction } from "../../../../../store/ReadAssignment/actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";

interface ExceptDraftProps {
  submissions: DeadlineSubmission[];
  submissionId: string;
}
// To grant/revoke draft submission exception
const ExceptDraft: React.FC<ExceptDraftProps> = ({
  submissions,
  submissionId,
}) => {
  const { timezone, deadlines } = useSelector(
    (state: any) => state.assignment.submissionData
  );

  const [selected, setSelected] = useState<string>("");
  const [exceptionDeadline, setDeadline] = useState<Dayjs | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialSelection = () => {
      const grantedDrafts = submissions.filter((c) => c?.exception === true);
      const index = grantedDrafts.length - 1;
      if (index > -1) {
        setSelected(grantedDrafts[index].draftSubmissionId);
      }
    };
    initialSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (event: SelectChangeEvent) => {
    setSelected(event.target.value);
  };

  const selectedItem = useCallback((): DeadlineSubmission | undefined => {
    return submissions.find((c) => c.draftSubmissionId === selected);
  }, [selected, submissions]);

  const handleDeadlineDateChange = (e: any) => {
    setDeadline(e);
  };

  const minDate = useCallback((): Dayjs => {
    const now = dayjs();
    try {
      const draftId = submissions.find(
        (c) => c.draftSubmissionId === selected
      )?.draftId;

      const deadlineEndDate = deadlines.find(
        (c) => c.draftId === draftId
      )?.draftDeadline;

      // Ensure only valid Dayjs instances are passed to dayjs.max
      const dates = [
        now,
        deadlineEndDate ? dayjs(deadlineEndDate) : null,
      ].filter((date): date is Dayjs => date !== null);

      const maxDate = dayjs.max(dates);
      return maxDate ?? now; // Return `now` if maxDate is null
    } catch {
      return now;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    // setDeadline
    const item = submissions.find((c) => c.draftSubmissionId === selected);
    if (item?.exceptionDeadline) {
      setDeadline(dayjs(item?.exceptionDeadline));
    }
  }, [selected]);

  const handleChangePermission = async () => {
    const item = selectedItem();
    const status = item ? !item.exception : undefined;
    if (status === undefined) return;
    let deadline:Dayjs|null =  (exceptionDeadline && status) ? exceptionDeadline : null;
    // console.log(submissionId,selected,status);
    // @ts-ignore
    dispatch(setDraftPermissionAction(
        submissionId,
        selected,
        deadline,
        status
      )
    );
  };

  return (
    <Stack>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Draft</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selected}
          onChange={handleSelect}
          label="Draft"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {submissions.map((item, index) => (
            <MenuItem
              value={item.draftSubmissionId}
              key={`dr-${item.draftSubmissionId}`}
            >
              {`${item.title} - ${item.status}`}
              {item?.exception && (
                <ListItemIcon sx={{ ml: "auto", color: "green" }}>
                  <CheckCircleIcon />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedItem() && (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={`Deadline :`}
              value={exceptionDeadline}
              minDate={minDate()}
              // maxDate={maxDate}
              onChange={(newValue) => {
                handleDeadlineDateChange(newValue);
              }}
              sx={{ width: "100%" }}
              timezone={timezone}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
          </LocalizationProvider>
          <Button fullWidth onClick={handleChangePermission}>
            {selectedItem()?.exception ? "Revoke" : "Grant"}
          </Button>
        </>
      )}
    </Stack>
  );
};

export default ExceptDraft;
