import React, { useMemo } from "react";

import { Box, Paper, Typography } from "@mui/material";

interface BoxPLotProps {
  data: any | undefined;
  rawData: any[] | undefined;
}

const CustomTooltip: React.FC<BoxPLotProps> = ({ data, rawData }) => {
  const tooltipContent = useMemo(() => {
    console.log("capture tooltip");
    const lowerPercentile = data.values[0]; // 10th percentile
    const upperPercentile = data.values[4]; // 90th percentile
    const filtered_data = rawData
      ?.filter((c) => c.group === data.group)
      .filter((c) => c.value >= lowerPercentile && c.value <= upperPercentile)
      .map((c) => c.value);
    const mean = filtered_data
      ? filtered_data?.reduce((a, b) => a + b, 0) / filtered_data?.length
      : 0;

    return (
      <Paper
        elevation={3}
        style={{
          padding: "16px",
          backgroundColor: "white",
          borderRadius: "8px",
          maxWidth: "200px",
        }}
      >
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", marginBottom: "8px" }}
        >
          {data.group}
        </Typography>
        <Box>
          <Typography variant="body2">
            {" "}
            Number of Assignments: {data.n}
          </Typography>
        </Box>
        <Typography variant="subtitle1" style={{ margin: "8px 0" }}>
          Quantiles
        </Typography>
        <Typography variant="body2">
          10%: {Math.round(data.values[0])}
        </Typography>
        <Typography variant="body2">
          25%: {Math.round(data.values[1])}
        </Typography>
        <Typography variant="body2">
          50%: {Math.round(data.values[2])}
        </Typography>
        <Typography variant="body2">
          75%: {Math.round(data.values[3])}
        </Typography>
        <Typography variant="body2">
          90%: {Math.round(data.values[4])}
        </Typography>
        <Typography variant="subtitle1" style={{ margin: "8px 0" }}>
          Summary
        </Typography>
        <Typography variant="body2">mean: {Math.round(mean)}</Typography>
        <Typography variant="body2">
          min: {Math.round(data.values[0])}
        </Typography>
        <Typography variant="body2">
          max: {Math.round(data.values[4])}
        </Typography>
      </Paper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.group]);

  return tooltipContent;
};

export default CustomTooltip;
