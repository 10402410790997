import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AssignmentTemplateResponse, NewAssignmentTemplate } from "./model";
import authHeader from "../../../../../services/auth-header";

const HOST = process.env.REACT_APP_API_URL;
const TEMP_CTRL = "api/auxfiles";
const ASSIGNMENT = "assignment";

export const fetchAssignmetTemplates = async (assignmentId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "GET",
    url: `${HOST}/${TEMP_CTRL}/${ASSIGNMENT}`,
    params: { assignmentId },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<AssignmentTemplateResponse[]> = await axios(
      axiosConfig
    );
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const createAssignmetTemplates = async (
  request: NewAssignmentTemplate
) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "PUT",
    url: `${HOST}/${TEMP_CTRL}/${ASSIGNMENT}`,
    params: {},
    data: request,
  };
  try {
    const apiCall: AxiosResponse<AssignmentTemplateResponse> = await axios(
      axiosConfig
    );
    return apiCall;
  } catch (err) {
    throw err;
  }
};
