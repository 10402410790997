import { Editor_Type } from "../../templatefiles/model";

export type ShowAnalyticsType = "NEVER" | "AFTER_GRADE" | "ALWAYS";

export interface ShowAnalyticsProps {
  type: ShowAnalyticsType;
  label: string;
}

export type TabType =
  | "DETAILS"
  | "STUDENTS"
  | "TEACHING ASSISTANTS"
  | "ASSIGNMENTS"
  | "ANALYTICS";
type CustomTab = {
  [key in TabType]: TabType;
};
export const tabs: CustomTab = {
  DETAILS: "DETAILS",
  STUDENTS: "STUDENTS",
  "TEACHING ASSISTANTS": "TEACHING ASSISTANTS",
  ASSIGNMENTS: "ASSIGNMENTS",
  ANALYTICS: "ANALYTICS",
};

export interface TemplateProps {
  open: boolean;
  type: Editor_Type | null;
}

export const initTemplateManager: TemplateProps = {
  open: true,
  type: null,
};
