import React, {  } from "react";
import { ResponsiveBoxPlot } from "@nivo/boxplot";
import CustomTooltip from "./tooltip/WPMBoxPlottooltip";

interface BoxPLotProps {
  data: any[] | undefined;
}



const WPMBoxPlot: React.FC<BoxPLotProps> = ({ data }) => {
  return (
    <ResponsiveBoxPlot
      data={data}
      margin={{ top: 60, right: 140, bottom: 60, left: 60 }}
      subGroupBy="subgroup"
      padding={0.12}
      enableGridX={true}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Assignments",
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Words Per Minute",
        legendPosition: "middle",
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      colors={{ scheme: "nivo" }}
      borderRadius={2}
      borderWidth={2}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      medianWidth={2}
      medianColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      whiskerEndSize={0.6}
      whiskerColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      motionConfig="stiff"
      tooltip={(e) => {
        return <CustomTooltip data={e["data"]} rawData={data}/>;
      }}
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemWidth: 60,
          itemHeight: 20,
          itemsSpacing: 3,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          symbolSize: 20,
          symbolShape: "square",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default WPMBoxPlot;
