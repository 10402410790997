import React from "react";
import { AssignmentTemplateResponse } from "./model";
import { IconButton, TableCell, TableRow } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
interface TemplateRowProps {
  data: AssignmentTemplateResponse;
  handleEdit:(item:AssignmentTemplateResponse)=>void
}

const TemplateRow: React.FC<TemplateRowProps> = ({ data,handleEdit }) => {
  return (
    <TableRow>
      <TableCell>{data.type}</TableCell>
      <TableCell>{data.contentType}</TableCell>
      <TableCell>{data.title}</TableCell>
      <TableCell>{data.templateTitle}</TableCell>
      <TableCell>
        <IconButton onClick={()=>handleEdit(data)}>
          <DriveFileRenameOutlineIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TemplateRow;
