import React, { useRef } from "react";
import Main from "../../elements/public/main";
import {
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import authToken from "../../services/auth-token";

const TestPage = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [alignment, setAlignment] = React.useState("docs");
  const [file, setFile] = React.useState("");

  const reloadDocument = () => {
    // alert(`${file}.${alignment}`);
    if (formRef.current) {
      console.log(formRef.current);
      formRef.current.submit();
    }
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };
  return (
    <Main>
      <Stack direction="row" spacing={3}>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="docx">Word Processor</ToggleButton>
          <ToggleButton value="pptx">Presentation</ToggleButton>
          <ToggleButton value="xlsx">Spreadsheet</ToggleButton>
          <ToggleButton value="odg">Draw</ToggleButton>
        </ToggleButtonGroup>
        <TextField onChange={(e) => setFile(e.target.value)} value={file} />
        <Button onClick={reloadDocument} variant="contained">Reload</Button>
      </Stack>
      <Container
        maxWidth={false}
        sx={{
          contain: "layout",
          height: ["inherit ", "-webkit-fill-available"],
          width: "95vw",
          pt: 2,
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: "88vh" }}>
          <form
            ref={formRef}
            action={`${process.env.REACT_APP_COLLABORA_HOST}WOPISrc=${process.env.REACT_APP_API_URL}/api/wopi/files/${file}.${alignment}`}
            encType="multipart/form-data"
            method="post"
            target="collabora-online-viewer"
            id="collabora-submit-form"
          >
            <input
              name="access_token"
              value={authToken()}
              type="hidden"
              id="access-token"
            />
            <input
              name="css_variables"
              value="--color-main-text=#000;--color-text-dark=#000;--color-text-darker=#000;--color-text-lighter=#000;--color-main-background=#fff;--color-background-dark=#fff;--color-background-darker=#fff;--color-background-lighter=#fff;"
              type="hidden"
            />

            <input
              name="ui_defaults"
              value="UIMode=tabbed;TextRuler=false;TextSidebar=false;"
              type="hidden"
            />
          </form>
          <Container
            maxWidth={false}
            sx={{
              contain: "layout",
              height: ["inherit ", "-webkit-fill-available"],
            }}
          >
            <iframe
              title="Collabora Online Viewer"
              id="collabora-online-viewer"
              name="collabora-online-viewer"
              allowFullScreen={true}
            ></iframe>
          </Container>
        </Grid>
      </Container>
    </Main>
  );
};

export default TestPage;
