import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { createAssignmetTemplates, fetchAssignmetTemplates } from "./service";
import {
  AssignmentTemplateResponse,
  initNewAssignmentTemplate,
  NewAssignmentTemplate,
  documentContentTypes,
  contentToEditor,
} from "./model";
import TemplateRow from "./TemplateRow";
import TemplateManagement from "../../../../templatefiles/Dashboard";

interface AssignmentTemplateManagerProps {
  assignmentId: string;
  handleClose: () => void;
}

const AssignmentTemplateManager: React.FC<AssignmentTemplateManagerProps> = ({
  assignmentId,
  handleClose,
}) => {
  const [data, setData] = useState<AssignmentTemplateResponse[]>([]);
  const [selectTemplate, setSelectTemplate] = useState<
    AssignmentTemplateResponse | undefined
  >();

  const [newRow, setNewRow] = useState<NewAssignmentTemplate | undefined>(
    undefined
  );

  const handleNewRowClick = () => {
    if (newRow) {
      setNewRow(undefined);
    } else {
      setNewRow({ ...initNewAssignmentTemplate, assignmentId: assignmentId });
    }
  };

  const handleSaveNewRow = async () => {
    try {
      if (newRow) {
        const response = await createAssignmetTemplates(newRow);
        if (response.status === 200) {
          setData((prev) => [...prev, response.data]);
          setNewRow(undefined);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    const getAssignmentTemplates = async (assignmentId) => {
      try {
        const response = await fetchAssignmetTemplates(assignmentId);
        if (response.status === 200) {
          setData(response.data);
        }
      } catch (e) {}
    };
    if (assignmentId !== "") {
      getAssignmentTemplates(assignmentId);
    }
  }, [assignmentId]);

  const close = () => {
    setData([]);
    setSelectTemplate(undefined);
    handleClose();
  };

  const handleSave = (response: AssignmentTemplateResponse) => {
    setData((prevstate) =>
      prevstate.map((item) => (item.id !== response.id ? item : response))
    );
    setSelectTemplate(undefined);
  };

  return (
    <Dialog
      open={assignmentId !== ""}
      onClose={close}
      scroll="body"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Button
          onClick={close}
          startIcon={<CloseSharpIcon />}
          sx={{ color: "ButtonText" }}
        ></Button>
        Contents and Templates Manager
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {!data && <>LOADING...</>}
            {data && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>File Type</TableCell>
                    <TableCell>Content Type</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Template</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => (
                    <TemplateRow data={item} handleEdit={setSelectTemplate} />
                  ))}
                  {newRow && (
                    <TableRow>
                      <TableCell>
                        <TextField
                          name="assignmentTemplateType"
                          disabled
                          fullWidth
                          variant="standard"
                          value={newRow.assignmentTemplateType}
                          size="small"
                        />
                      </TableCell>

                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Content Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newRow.contentType}
                            label="Age"
                            onChange={(e: any) =>
                              setNewRow({
                                ...newRow,
                                contentType: e.target.value,
                              })
                            }
                            size="small"
                          >
                            {documentContentTypes.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          id="standard-basic"
                          label="title"
                          variant="standard"
                          value={newRow.title}
                          onChange={(e: any) =>
                            setNewRow({
                              ...newRow,
                              title: e.target.value,
                            })
                          }
                        />
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <IconButton onClick={handleSaveNewRow}>
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      align="center"
                      sx={{ textAlign: "center" }}
                    >
                      <IconButton onClick={handleNewRowClick}>
                        {newRow ? <RemoveIcon /> : <AddCircleIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
        <TemplateManagement
          open={!!selectTemplate}
          type={
            selectTemplate
              ? contentToEditor[selectTemplate.contentType]
              : "WRITER"
          }
          handleClose={() => setSelectTemplate(undefined)}
          selectTemplate={selectTemplate}
          handleSave={handleSave}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AssignmentTemplateManager;
