import {
  AppBar,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import authToken from "../../../../services/auth-token";

interface AdditionalFilesDashboardProps {
  selectedFile: any | undefined;
  handleClose: () => void;
}
const AdditionalFilesDashboard: React.FC<AdditionalFilesDashboardProps> = ({
  selectedFile,
  handleClose,
}) => {
  const REACT_APP_ADDITION = process.env.REACT_APP_ADDITION;

  useEffect(() => { 
    const reloadDocument = () => {
      const formformExists: any = document.getElementById(
        "additional-file-submit-form"
      );
  
      if (formformExists) {
        formformExists.submit();
      }
    };

    if(!!selectedFile ){
      console.log(selectedFile)
      setTimeout(reloadDocument, 3000);

    }

  }, [selectedFile]);
  return (
    <Dialog open={!!selectedFile} fullScreen onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {selectedFile && (
              <>
                Additional Files -{" "}
                {!!selectedFile.title ? selectedFile.title : selectedFile.type}
              </>
            )}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {!!selectedFile && (
          <>
            <form
              action={`${REACT_APP_ADDITION}${selectedFile.fileId}`}
              encType="multipart/form-data"
              method="post"
              target={"additional-file-viewer"}
              id="additional-file-submit-form"
            >
              <input
                name="access_token"
                value={authToken()}
                type="hidden"
                id="access-token"
              />

              {/* <input
                name="css_variables"
                value="--color-main-text=#000;--color-text-dark=#000;--color-text-darker=#000;--color-text-lighter=#000;--color-main-background=#fff;--color-background-dark=#fff;--color-background-darker=#fff;--color-background-lighter=#fff;"
                type="hidden"
              /> */}

              <input
                name="ui_defaults"
                value="UIMode=tabbed;TextRuler=false;TextSidebar=false;"
                type="hidden"
              />
              {/* <input type="submit" value="Submit" /> */}

            </form>
            <Grid item xs={12}  >
              <Container
                sx={{
                  contain: "layout",
                  height: ["inherit ", "86vh"],
                  width: ["inherit ", "98vw"],
                  mt: 2,
                }}
                maxWidth={false} 
                disableGutters={true}
              >
                <iframe
                  title="Collabora Online Viewer"
                  id="additional-file-viewer"
                  name="additional-file-viewer"
                  allowFullScreen={true}
                ></iframe>
              </Container>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AdditionalFilesDashboard;
